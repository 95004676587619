<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                :value="searchFields.email"
                @input="inputChange($event, 'email')"
              />
            </b-form-group>
          </b-col>
<!--          <b-col-->
<!--            cols="12"-->
<!--            md="4"-->
<!--            class="mb-md-0 mb-2"-->
<!--          >-->
<!--            <b-form-group-->
<!--              :label="$t('labels.phone')"-->
<!--              label-for="phone"-->
<!--            >-->
<!--              <b-form-input-->
<!--                id="phone"-->
<!--                :value="searchFields.phone"-->
<!--                @input="inputChange($event, 'phone')"-->
<!--              />-->
<!--            </b-form-group>-->
<!--          </b-col>-->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
<!--          <b-col-->
<!--            cols="12"-->
<!--            md="4"-->
<!--            class="mb-md-0 mb-2"-->
<!--          >-->
<!--            <label>{{ $t('models.roles') }}</label>-->
<!--            <v-select-->
<!--              v-model="searchFields.custom_roles"-->
<!--              :dir="$store.state.appConfig.layout.direction"-->
<!--              :options="roles"-->
<!--              multiple-->
<!--              :reduce="item => item.id"-->
<!--              label="name"-->
<!--              class="w-100"-->
<!--            />-->
<!--          </b-col>-->
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :permissionKey='"user"'

    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'users',
      singularName: 'user',
      roles: [],
      searchFields: {
        name: null,
        phone: null,
        status: null,
        email: null,
        custom_roles: [],
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    getRoles() {
      this.axios.get('/lists/role')
        .then(res => {
          this.roles = res.data.data
        })
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 1,
          name: this.$t('statuses.active'),
        },
        {
          id: 0,
          name: this.$t('statuses.inactive'),
        },
      ]
    },
  },
  // eslint-disable-next-line vue/order-in-components
  created() {
    this.getRoles()
  },
}
</script>
